import React, { Component } from 'react';
//import React from 'react';
import './App.css';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import withAuth from './components/withAuth';
import AuthService from './components/AuthService';
import { withRouter, BrowserRouter } from 'react-router-dom';

const Auth = new AuthService();


class App extends Component {

  handleLogout() {
    Auth.logout()
    this.props.history.replace('/login');
  }

  routeNearby() {
    this.props.history.replace('/nearby');
  }

  render() {
    return (

      <div className="App">
        <div className="App-header">
          <h2>Welcome User</h2>
          <Button color="primary" onClick={this.routeNearby.bind(this)} >Find Nearby</Button>
      <p></p>
          <Button color="warning" onClick={this.handleLogout.bind(this)}>Logout</Button>
        </div>
        <p className="App-intro">
          <button type="button" className="form-submit" onClick={this.handleLogout.bind(this)}>Logout</button>
        </p>
      </div>

    );
  }
}

export default withAuth(App);
