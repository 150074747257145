import React, { Component } from 'react';
import { Table } from 'reactstrap';
import './Login.css';
import withAuth from './withAuth';
import AuthService from './AuthService';
import { geolocated } from "react-geolocated";
import RenderCoordinates from './RenderCoordinates';
const Auth = new AuthService();

function RenderLocations({ locationsArr }) {
    if (locationsArr != null) {
        const location_div = locationsArr.map((location) => {
            return (
                <tr>
                    <td>{location.distance.toFixed(2)}</td>
                    <td>{location.name}</td>
                    <td>{location.street}</td>
                </tr>
            );
        });

        return (
            <tbody>{location_div}</tbody>
        );
    } else {
        return (
            <div><h3>No locations</h3></div>
        );
    }
}

class Nearby extends Component {
    constructor() {
        super();
        this.Auth = new AuthService();
        this.state = { locationsArr: null, latitude: false, longitude: false };
        this.nearbyAPI = this.nearbyAPI.bind(this);
    }
    handleLogout() {
        Auth.logout()
        this.props.history.replace('/login');
    }

    nearbyAPI(location) {

        //fetch('http://127.0.0.1:8000/api/customers/nearby/' + location.latitude + '/' + location.longitude, {
        fetch('https://api.henkelsoft.com/api/customers/nearby/' + location.latitude + '/' + location.longitude, {
            method: 'GET',
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
            },
            redirect: 'manual', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }).then(response => response.json())
            .then(
                data => {
                    if (this.state.locationsArr == null) {
                        this.setState({ locationsArr: data });
                    }
                }
            );
        console.log('set nearby lcoations');
    }

    componentDidMount() {

    }

    render() {
        return !this.props.isGeolocationAvailable ? (
            <div>Your browser does not support Geolocation</div>
        ) : !this.props.isGeolocationEnabled ? (
            <div>Geolocation is not enabled</div>
        ) : this.props.coords ? (

            <div className="App">
                <div className="App-header">
                    <RenderCoordinates latitude={this.props.coords.latitude} longitude={this.props.coords.longitude} nearbyAPI={this.nearbyAPI} />
                    <h2>Nearby Customers</h2>
                    <Table className='table-dark'>
                        <thead>
                            <tr>
                                <th>Distance</th>
                                <th>Name</th>
                                <th>Street</th>
                            </tr>
                        </thead>
                        <RenderLocations locationsArr={this.state.locationsArr} />
                    </Table>
                    <button type="button" className="form-submit" onClick={this.handleLogout.bind(this)}>Logout</button>
                </div>
            </div>

        ) : (
                        <div>Getting the location data&hellip; </div>
                    );
    }
}

export default withAuth(
    geolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    })(Nearby)
);