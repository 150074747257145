import React, { Component } from 'react';

class RenderCoordinates extends Component {
    constructor() {
        super();
    }

    render() {
        if (this.props.latitude != null) {
            let location = { latitude: this.props.latitude, longitude: this.props.longitude };
            this.props.nearbyAPI(location);
            return (
                <div>{this.props.latitude.toFixed(2)} / {this.props.longitude.toFixed(2)}</div>
            );

        } else {
            return (
                <div></div>
            );
        }
    }
}

export default RenderCoordinates;